@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    
    font-family: 'Poppins', sans-serif;
}
.create-candidate-container {
  background-color: #f0f5ff; /* Light blue background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle effect */
}

.create-candidate-container h2 {
  color: #333; /* Dark text color */
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}



button[type="submit"] {
  background-color: #007bff; /* Blue button background */
  color: #fff; /* White text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button[type="submit"]:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(4, 52, 111, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff; /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for a subtle effect */
  position: relative;
  max-width: 400px;
  width: 100%;
  overflow: hidden;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  background-color: #187fe6;
  padding: 5px;
}
.submit1{
  width: 100%;
  background-color: #0E1629 !important;
  border-radius: 150px !important;
}
.changed{
  width: 100%;
  background-color: #0E1629 !important;
  border-radius: 150px !important;
  animation: move1 1.5s forwards,move2 1.5s infinite 1.5s forwards;
}
@keyframes move1 {
  
  100%{
    margin-left: 40%;
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
    color: transparent;
    border-top: 5px solid #0056b3;
  }
}
@keyframes move2 {
  0%{
    margin-left: 40%;
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
    color: transparent;
    border-top: 5px solid #0056b3;
  }
  100%{
    margin-left: 40%;
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
    color: transparent;
    border-top: 5px solid #0056b3;
    transform: rotate(360deg);
  }
}
.input-field{
  width: 100% !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  border: 0px solid #0056b3 !important;
  border-bottom: 2px solid #539A8D !important;
  outline: none;
}

.input-field-email{
  width: 93% !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  border: 0px solid #0056b3 !important;
  border-bottom: 2px solid #539A8D !important;
  outline: none;
}
.select{
  background-color: transparent !important;
  border-radius: 0px !important;
  border: 0px solid #0056b3 !important;
  border-bottom: 2px solid #539A8D !important;
  outline: none;
}
.fieldselect{
  background-color: transparent !important;
  border-radius: 0px !important;
  border: 0px solid #0056b3 !important;
  border-bottom: 2px solid #539A8D !important;
  outline: none;
}
.form-group label{
  font-size: 15px;
}