.ResultMain{
}
.ResultImg{
    height: 84px;
    padding: 18px 0;
    width: 33%;
}
.img{
    display: flex;
    width: 30%;
}
.ResultImgDiv{
    align-items: center;
    background-color: #c4f2e8;
    justify-content: center;
    border-radius: 20px;
}
.ResultStats{
    margin-top: 14px;
    align-items: center;
    gap: 6px;
    padding: 0 32px;
}
.ResultHeading{
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.ResultText{
    font-weight: 400;
    font-size: 18px;
}
