.SignUpMain{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}
.SignUpContainer{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
    display: flex;
    max-width: 880px;
    width: 880px;
    margin: 14px 0;
    flex-direction: row;
    flex-wrap: wrap;
}
@media (max-width: 700px){
    .SignUpContainer{
        margin: 14px 16px;
    }
}
.SignUpImage{
    width: 50%;
    border-radius: 10px 0 0 10px;
}
.SignUpImage img{
    height: 100%;
    width: 100%;
    border-radius: 10px 0 0 10px;
    object-fit: cover;
}
@media (max-width: 700px){
    .SignUpImage{
        display: none;
    }
}
.SignUpForm{
    width: 50%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
@media (max-width: 700px){
    .SignUpForm{
        width: 100%;
    }
}
.SignUpInput{
    width: 100%;
    margin: 4px 0;
    border-radius: 5px;
    box-sizing: border-box;
}
.SignUpInput label{
    font-size: 14px;
}
.SignUpInput input{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 8px;
    box-sizing: border-box;
}
.SignUpInput button{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 8px;
    box-sizing: border-box;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    margin-top: 22px;
    cursor: pointer;
}
.SignUpInput button:hover{
    background-color: #45a049;
}