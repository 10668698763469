.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.profile-card {
  /* Blue border */
  max-width: 700px;
  padding: 10px 60px!important;
  border-radius: 8px;
  padding: 20px;
  width: 60vh;
  text-align: center;
  box-shadow: 0px 0px 10px 0px #000;
  background-color: #f0f0f0;
  font-size: 20px;
  padding-top: 0px;
}
@media only screen and (max-width: 400px) {
  .profile-card {
    /* Blue border */
    min-width: 400px;
    border-radius: 8px;
    padding: 20px;
    width: fit-content;
    text-align: center;
    box-shadow: 0px 0px 0px 0px #000;
    background-color: #fff;
    font-size: 20px;
    padding-top: 0px;
  }
}
.upload-resume-btn {
  background-color: #007bff; /* Blue button background */
  color: #fff; /* White text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.upload-resume-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}
.profile-card h2 {
  margin-bottom: 10px;
}
.title-logo {
  height: 30px;
  width: 30px;
  margin-right: 24px;
}
.role-logo {
  transform: translate(-5px, -30px);
}
.status-logo {
  transform: translate(-10px, -10px);
  height: 20px;
  width: 20px;
  margin-top: 10px;
}
.phone-logo {
  transform: translate(-10px, 5px) !important;
  height: 20px;
  width: 20px;
  margin-top: 10px;
}
.skills-logo {
  transform: translate(-10px, 25px) !important;
  height: 20px;
  width: 20px;
  margin-top: 10px;
}
.email {
  left: 10% !important;
  width: 100%;
}
.role {
  left: 0%;
  width: 100%;
}
.candidate-list-container{
  flex-grow: 1;
  padding-inline: 34px;
  padding-top: 12px;
}
.cand-container {
  display: flex;
  height: fit-content !important;
  box-sizing: border-box;
  justify-content: normal!important;
  padding-inline: 22px;
}
.titles {
  text-align: left;
  width: 100%;
}
.icon {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.button1 {
  height: 52px;
  width: 100% !important;
  background: #5da394;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}
.button1.active {
  margin-bottom: 18px!important;
  width: 100% !important;
  height: 20px;
  transform: translate(0, 10%) !important;
}
.button1::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: #0e1629;
  border-radius: 55px;
  transition: all 1s ease-in-out;
}
.button1.active::before {
  animation: layer 1s ease-in-out forwards;
}
@keyframes layer {
  100% {
    left: 0%;
  }
}
.button1 .content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}
.button1.active .content {
  transform: translateY(60px);
}
.button1 .content i,
.button1 .content .button-text {
  color: #0e1629;
  font-size: 20px !important;
  font-weight: 500;
}
.button1 .content .button-text {
  font-size: 28px;
  margin-left: 8px;
}
.content {
  transform: translateX(-20px) translateY(-20px);
  background-color: transparent !important;
}
/* hello*/
.button2 {
  height: 52px;
  margin-top: 18px;
  width: 100% !important;
  background: #5da394;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}
.button2.active {
  margin-bottom: 18px!important;
  width: 100% !important;
  height: 20px;
  transform: translate(0, 10%) !important;
}
.button2::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: #0e1629;
  border-radius: 55px;
  transition: all 1s ease-in-out;
}
.button2.active::before {
  animation: layer 1s ease-in-out forwards;
}
@keyframes layer {
  100% {
    left: 0%;
  }
}
.button2 .content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}
.button2.active .content {
  transform: translateY(60px);
}
.button2 .content i,
.button2 .content .button-text {
  color: #0e1629;
  font-size: 20px !important;
  font-weight: 500;
}
.button2 .content .button-text {
  font-size: 28px;
  margin-left: 8px;
}
.content {
  transform: translateX(-20px) translateY(-20px);
  background-color: transparent !important;
}
.button3 {
  transform: translate(45%, 10%) !important;
  height: 55px;
  width: 50% !important;
  background: #5da394;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
  padding: 10px 0%;
  box-sizing: border-box;
}
.button3.active {
  height: 20px;
  width: 90% !important;
  transform: translate(10%, 300%) !important;
}
.button3::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: #0e1629;
  border-radius: 55px;
  transition: all 1s ease-in-out;
}
.button3.active::before {
  animation: layer 1s ease-in-out forwards;
}
@keyframes layer {
  100% {
    left: 0%;
  }
}
.button3 .content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}
.button3.active .content {
  transform: translateY(60px);
}
.button3 .content i,
.button3 .content .button-text {
  color: #0e1629;
  font-size: 20px !important;
  font-weight: 500;
}
.button3 .content .button-text {
  font-size: 28px;
  margin-left: 8px;
}
.content {
  transform: translateX(-20px) translateY(-20px);
  background-color: transparent !important;
}
.button4 {
  transform: translate(45%, 10%) !important;
  height: 60px !important;
  width: 50% !important;
  background: #5da394;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
}
.button4.active {
  height: 20px;
  width: 90% !important;
  transform: translate(10%, 300%) !important;
}
.button4::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: #0e1629;
  border-radius: 55px;
  transition: all 1s ease-in-out;
}
.button4.active::before {
  animation: layer 1s ease-in-out forwards;
}
@keyframes layer {
  100% {
    left: 0%;
  }
}
.button4 .content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}
.button4.active .content {
  transform: translateY(60px);
}
.button4 .content i,
.button4 .content .button-text {
  color: #0e1629;
  font-size: 20px !important;
  font-weight: 500;
}
.button4 .content .button-text {
  font-size: 28px;
  margin-left: 8px;
}
.content {
  transform: translateX(-20px) translateY(-20px);
  background-color: transparent !important;
}
