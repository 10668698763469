@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    
    font-family: 'Poppins', sans-serif;
}
.interviewer-profile-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff;
  }
  
  .profile-details {
    border: 1px solid rgba(204, 204, 204,0.18);
    border-radius: 10px;
    padding: 60px;
    padding-top: 30px; /* Increase the padding */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.37);
    height: 60vh;
    background-color: #f0f0f0;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
  }
  
  .profile-info input {
    padding: 20px 30px; 
    font-size: 18px;
    width: fit-content;
    border-radius: 8px;
    background: transparent;
  }
  .input-with-icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 22px;     
  }
  .InputIcons{
    margin-right: 4px;
  }
  .profile-info button {
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px; 
    cursor: pointer;
  }
  
  .profile-info button:hover {
    background-color: #0056b3;
  }
.flex_image {
  position: relative;
  width: 100px; /* Adjust the width */
  height: 100px; /* Adjust the height */
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-bottom: 30px;
}

.flex_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.hover-image {
  display: none; /* Initially hide the second image */
}

.flex_image.hovered {
  transform: rotateY(180deg); /* Rotate the div on the y-axis by 180 degrees */
}

.flex_image.hovered .hover-image {
  display: block; /* Show the second image when the div is hovered */
}
.input-with-icon input{
  border: 0px;
  outline: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid #000;
  border-radius: 0px;
  padding-bottom: 10px;
}
#email-logo{
  height: 40px !important;
  width: 40px !important;
  transform: translateY(0px) !important;
}
.role-logo{
  height: 30px;
  width: 30px;
  transform: translateY(5px);
}
.username{
  height: 35px;
  width: 35px;
}
.button{
  transform: translate(10%, 50%) !important;
  height: 70px;
  width: 90%!important;
  background: #5DA394;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  overflow: hidden;
}
.button.active{
  height: 20px;
  width: 90% !important;
  transform: translate(10%,300%) !important;
}
.button::before{
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: #0E1629;
  border-radius: 55px;
  transition: all 1s ease-in-out;
}
.button.active::before{
  animation: layer 1s ease-in-out forwards;
}
@keyframes layer {
  100%{
    left: 0%;
  }
}
.button .content{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}
.button.active .content{
  transform: translateY(60px);
}
.button .content i,
.button .content .button-text{
  color: #0E1629;
  font-size: 40px;
  font-weight: 500;
}
.button .content .button-text{
  font-size: 28px;
  margin-left: 8px;
}
.content{
  transform: translateX(-20px) translateY(-20px);
  background-color: transparent !important;
} 
@media only screen and (max-width: 768px) {
  /* Your styles here */
  .profile-details {
    border: 0px solid rgba(204, 204, 204,0.18);
    border-radius: 10px;
    padding: 10px;
    padding-top: 10px; /* Increase the padding */
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.37);
    height: 60vh;
    background-color: #fff
  }
  .button{
    transform: translate(5%, 50%);
    height: 50px;
    width: 300px;
    background: #5DA394;
    border-radius: 55px;
    cursor: pointer;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    overflow: hidden;
  }
  .hover-image{
    display: block;
    height: 50px !important;
    width: 50px !important;
    position: relative;
    transform: translate(140%,140%);
  }
  .flex_image.hovered {
    transform: rotateY(0deg); /* Rotate the div on the y-axis by 180 degrees */
  }
}
