.flex{
  display: flex;
}
.flex-column{
  flex-direction: column;
}
.flex-row{
  flex-direction: row;
}
.collapsed{
  display: none;
}