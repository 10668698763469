/* App.css */

/* Navbar styles */
.candidates {
    display: flex;
    flex-direction: column;
    font-size: large;
  }
  
  .checkbox-container {
    display: flex;
    flex-direction: row;
    margin-right: 20px; /* Adjust as needed */
    margin-bottom: 10px; /* Adjust as needed */
    font-size: large;
    border: 1px solid #ccc; /* Border style */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
    padding: 10px; /* Padding for spacing */
  }
  
  .checkbox-container input[type="checkbox"] {
    margin-bottom: 5px; /* Adjust as needed */
  }
  
  .checkbox-container label {
    
    font-size: large;
  }
  
  
  .navbar {
    background-color: #007bff; /* Blue color */
    color: white;
    padding: 10px;
    text-align: center;
  }
  
  /* Content styles */
  .content2 {
    display: flex;
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    justify-content: space-between;
    padding-top: 30px;
    gap: 32px;
    padding-left: 24px;
  }
  
  /* Question generation section styles */
  .question-generation {
    flex: 1;
    margin-right: 20px;
    margin-left: 40px;
  }
  
  .question-generation h1 {
    margin-bottom: 20px;
  }
  
  .question-generation input[type="text"] {
    padding: 10px;
    margin-right: 10px;
  }
  
  .question-generation .checkbox-container {
    display: flex;
    flex-direction: row;
  }
  
  .question-generation button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
  
  /* Candidate selection section styles */
  .candidate-selection {
    flex: 1;
  }
  
  .candidate-selection h2 {
    margin-bottom: 20px;
  }
  
  .roles {
    margin-bottom: 20px;
  }
  
  .roles button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #558aeb;
    color: rgb(17, 6, 6);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    font-size: large;
  }
  
  .candidates div {
    margin-bottom: 10px;
  }
  
  .candidates label {
    margin-left: 5px;
  }
  
  .generateQuestion {
    /* Add your styles here */
  }
  
  .sendEmail {
    display: flex;
  }
  
  .sendEmail button {
    color: rgb(229, 207, 207);
    background-color: rgb(178, 12, 12);
    margin-top: 22px;
  }
  .success-message {
    background-color: #4CAF50; /* Green */
    color: white;
   margin-left: 30px;
   margin-right: 40px;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
  }
  
@media (max-width: 910px){
  .content2{
    flex-direction: column-reverse;
  }
  .question-generation{
    margin-left: 0;
  }
}